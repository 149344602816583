import React, { useState } from "react";
import "../../css/ServicesPage/Contact.css";
import img1 from "../../images/contact/Icon.png";
import emailjs from "emailjs-com";

function Contact() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState(""); // Added subject state
  const [message, setMessage] = useState(""); // Added message state

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: fullName,
      from_email: email,
      subject: subject,
      message: message,
    };

    // Replace with your actual service ID, template ID, and user ID
    emailjs
      .send(
        "service_3nzhldp",
        "template_iw9qsoa",
        templateParams,
        "MOha9IT6CuBtM52j8"
      )
      .then(
        (response) => {
          console.log("Message sent successfully", response);
          window.alert("Message sent successfully!");
          // Optionally reset the form after successful submission
          setFullName("");
          setEmail("");
          setSubject("");
          setMessage("");
        },
        (error) => {
          console.error("Failed to send message", error);
          window.alert("Failed to send message, reach limit this month!");
        }
      );
  };

  return (
    <div className="contact-container">
      <div className="circle-container">
        <p>
          <span className="green">Get In Touch </span>
        </p>

        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <input
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
              placeholder="Full Name"
              className="form-input"
            />
          </div>

          <div className="form-group">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
              className="form-input"
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              placeholder="Subject"
              className="form-input"
            />
          </div>

          <div className="inputBox-container">
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder="Enter your message here.."
              className="form-inputbox"
            />
          </div>

          <button type="submit" className="submit-btn">
            <img src={img1} className="img" />
            SEND MESSAGE
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
