import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/Navbar/Navbar.css";

function Navbar({
  scrollToHomechoose,
  scrollToHomemid,
  scrollToCarousel,
  scrollToContact,
}) {
  const [scrollingDown, setScrollingDown] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    // Check if the user is at the bottom of the page
    if (currentScrollY + windowHeight >= scrollHeight) {
      setIsBottom(true); // User is at the bottom of the page
    } else {
      setIsBottom(false); // User is not at the bottom
    }

    // Only hide navbar if scrolling down and we are not at the top or bottom
    if (!isBottom && currentScrollY > lastScrollY && currentScrollY > 100) {
      setScrollingDown(true); // User is scrolling down and not at the top or bottom
    } else {
      setScrollingDown(false); // Either scrolling up or at the top
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY, isBottom]);

  const closeMobileMenu = () => setClick(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <>
      <nav className={`navbar ${scrollingDown ? "hidden" : ""}`}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}></Link>
          <div className="menu-icon" onClick={handleClick}>
            {/* <i
              className={click ? "fas fa-times" : "fas fa-bars"}
              style={{ color: "white" }}
            /> */}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <p className="nav-item-p1">Home</p>
              </Link>
            </li>
            <li className="nav-item">
              <a
                href="#about"
                className="nav-links"
                onClick={() => {
                  scrollToHomechoose();
                  closeMobileMenu();
                }}
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#mywork"
                className="nav-links"
                onClick={() => {
                  scrollToCarousel();
                  closeMobileMenu();
                }}
              >
                Work
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#contact-us"
                className="nav-links"
                onClick={() => {
                  scrollToContact();
                  closeMobileMenu();
                }}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
