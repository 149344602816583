import React from "react";
import "../../css/Homepage/Homemid.css";
import chat from "../../images/homemid/Chat.png";
import coin from "../../images/homemid/Coin.png";
import walletinapp from "../../images/homemid/walletinapp.png";

function Homemid() {
  return (
    <div className="what-i-do-container">
      <h2 className="what-i-do-title">What I Do</h2>

      <div className="what-i-do-categories">
        <div className="category-card">
          <h3 className="category-title">Web Development</h3>
          <p className="category-description">
            Building responsive and user-friendly websites using modern
            technologies like React, Vue.js, and more.
          </p>
        </div>

        <div className="category-card">
          <h3 className="category-title">Mobile Development</h3>
          <p className="category-description">
            Creating intuitive mobile applications for iOS and Android using
            React Native and Swift.
          </p>
        </div>

        <div className="category-card">
          <h3 className="category-title">UI/UX Design</h3>
          <p className="category-description">
            Designing clean, attractive, and functional interfaces to enhance
            user experiences.
          </p>
        </div>

        <div className="category-card">
          <h3 className="category-title">SEO</h3>
          <p className="category-description">
            Optimizing websites to improve search engine rankings and enhance
            organic traffic.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Homemid;
