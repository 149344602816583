import React, { useState, useEffect } from "react";
import "../../css/Homepage/Hometop.css";

import { Link } from "react-router-dom";

function Hometop() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [highlightPosition, setHighlightPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    // Handle window resizing
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Tracking mouse movement
  useEffect(() => {
    const handleMouseMove = (event) => {
      setHighlightPosition({
        x: event.clientX,
        y: event.clientY,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup the event listener when the component is unmounted
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div className="hometop-container">
      <div className="hometop-title">
        <h1>
          <span className="hometop-greeting">Hi, my name is</span>
          <span className="hometop-name">Yue Zeng</span>
          <div className="special-color-flex">
            <span className="hometop-span2">Front End Engineer</span>
          </div>
          <div className="hometop-addcolor"></div>

          <p className="hometop-tagline">
            Let's Build Your Own Web & Mobile Applications Today
          </p>
        </h1>
      </div>

      {/* Highlight Area */}
      <div
        className="highlight"
        style={{
          left: `${highlightPosition.x - 200}px`, // Adjusted to center the highlight on the mouse
          top: `${highlightPosition.y - 200}px`, // Adjusted to center the highlight on the mouse
        }}
      ></div>
    </div>
  );
}

export default Hometop;
