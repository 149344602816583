import React from "react";
import "../../css/Homepage/MobileImageCarousel.css"; // 引入样式
import f1 from "../../images/workScreenShot/f1.png";
import f2 from "../../images/workScreenShot/f2.png";
import f3 from "../../images/workScreenShot/f3.png";
import f7 from "../../images/workScreenShot/f7.png";

import spa1 from "../../images/workScreenShot/spa1.png";
import spa2 from "../../images/workScreenShot/spa2.png";
import spa3 from "../../images/workScreenShot/spa3.png";
import spa4 from "../../images/workScreenShot/spa4.png";

import nlk1 from "../../images/workScreenShot/nlk1.png";
import nlk2 from "../../images/workScreenShot/nlk2.png";
import nlk3 from "../../images/workScreenShot/nlk3.png";
import nlk4 from "../../images/workScreenShot/nlk4.png";
import nlk5 from "../../images/workScreenShot/nlk5.png";
import nlk6 from "../../images/workScreenShot/nlk6.png";

import fp1 from "../../images/workScreenShot/fp1.png";
import fp2 from "../../images/workScreenShot/fp2.png";
import fp3 from "../../images/workScreenShot/fp3.png";
import fp4 from "../../images/workScreenShot/fp4.png";
import fp5 from "../../images/workScreenShot/fp5.png";

import ny1 from "../../images/workScreenShot/ny1.png";
import ny2 from "../../images/workScreenShot/ny2.png";
import ny3 from "../../images/workScreenShot/ny3.png";
import ny4 from "../../images/workScreenShot/ny4.png";
import ny5 from "../../images/workScreenShot/ny5.png";
import ny6 from "../../images/workScreenShot/ny6.png";
const images = [
  { id: 1, src: f1, alt: "Image 1" },
  { id: 2, src: f2, alt: "Image 2" },
  { id: 3, src: f3, alt: "Image 3" },
  { id: 7, src: f7, alt: "Image 7" },
];
const images2 = [
  { id: 12, src: spa1, alt: "Image 1" },
  { id: 22, src: spa2, alt: "Image 2" },
  { id: 32, src: spa4, alt: "Image 3" },
  { id: 72, src: spa3, alt: "Image 7" },
];

const images3 = [
  { id: 13, src: fp1, alt: "Image 1" },
  { id: 23, src: fp2, alt: "Image 2" },
  { id: 33, src: fp3, alt: "Image 3" },
  { id: 43, src: fp4, alt: "Image 4" },
  { id: 53, src: fp5, alt: "Image 5" },
];

const images4 = [
  { id: 14, src: ny1, alt: "Image 1" },
  { id: 24, src: ny2, alt: "Image 2" },
  { id: 34, src: ny3, alt: "Image 3" },
  { id: 44, src: ny4, alt: "Image 4" },
  { id: 54, src: ny5, alt: "Image 5" },
  { id: 64, src: ny6, alt: "Image 6" },
];

const images5 = [
  { id: 15, src: nlk1, alt: "Image 1" },
  { id: 25, src: nlk2, alt: "Image 2" },
  { id: 35, src: nlk3, alt: "Image 3" },
  { id: 45, src: nlk4, alt: "Image 4" },
  { id: 55, src: nlk5, alt: "Image 5" },
  { id: 65, src: nlk6, alt: "Image 6" },
];

const MobileImageCarousel = () => {
  return (
    <div className="mcarousel-container">
      <div className="mcarousel-title" style={{ paddingTop: "5%" }}>
        WEB PROJECTS
      </div>
      <div className="mcarousel-box">
        {images.map((image) => (
          <div key={image.id} className="mcarousel-item">
            <img src={image.src} alt={image.alt} className="mcarousel-image" />
          </div>
        ))}

        {images2.map((image) => (
          <div key={image.id} className="mcarousel-item">
            <img src={image.src} alt={image.alt} className="mcarousel-image" />
          </div>
        ))}
      </div>
      <div className="mcarousel-title" style={{ paddingTop: "5%" }}>
        MOBILE PROJECTS
      </div>
      <div className="mcarousel-box">
        {images3.map((image) => (
          <div key={image.id} className="mcarousel-item">
            <img src={image.src} alt={image.alt} className="mcarousel-image" />
          </div>
        ))}
        {images4.map((image) => (
          <div key={image.id} className="mcarousel-item">
            <img src={image.src} alt={image.alt} className="mcarousel-image" />
          </div>
        ))}
        {images5.map((image) => (
          <div key={image.id} className="mcarousel-item">
            <img src={image.src} alt={image.alt} className="mcarousel-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileImageCarousel;
