import React from "react";

import "../../css/Footer/Footer.css";
import { Link } from "react-router-dom";

// import img1 from "../../images/footer/CTA-2.png";
// import img2 from "../../images/footer/Logo.png";
// import img3 from "../../images/footer/Facebook.png";
// import img4 from "../../images/footer/Instagram.png";
// import img5 from "../../images/footer/LinkedIn.png";
// import img6 from "../../images/footer/Twitter.png";
// import img7 from "../../images/footer/YouTube.png";
function Footer() {
  return (
    <div className="footer-container">
      <section className="social-media">
        <div className="social-media-wrap">
          <small className="website-rights">
            <p className="wr-fonts"> Designed & Built by Yue Zeng.</p>
          </small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
