import React from "react";
import "../../css/Homepage/Homechoose.css";

function Homechoose() {
  return (
    <section className="about-me">
      <div className="about-me-container">
        <h2 className="about-me-title">Hello! I'm Yue Zeng.</h2>
        <p className="about-me-description">
          I'm a {""}
          <strong>Front-End Engineer</strong> dedicated to crafting intuitive
          and engaging digital experiences. I hold a Bachelor's degree in
          Computer Science from Queens College and have hands-on experience in
          building responsive websites and mobile applications.
        </p>
        <p className="about-me-description">
          Over the years, I've developed a keen eye for{" "}
          <strong>UI/UX design</strong>, creating interfaces that are both
          visually appealing and user-friendly. I'm proficient in{" "}
          <strong>React.js, Vue.js, React Native,</strong> and other modern
          frameworks, and I'm always eager to learn new technologies to improve
          my skills.
        </p>
        <p className="about-me-description">
          I enjoy taking on challenges that push me to find creative solutions
          and see them through to completion.
        </p>
        <p className="about-me-description">
          When I’m not coding, I'm probably exploring the great outdoors or
          fishing.
        </p>
      </div>
    </section>
  );
}

export default Homechoose;
