import React, { useState, useEffect, useRef } from "react";
import Hometop from "./Hometop";
import Homemid from "./Homemid";
import Homechoose from "./Homechoose";
import "../../css/Homepage/homepage.css";

import Contact from "../ServicesPage/Contact";
import ImageCarousel from "./ImageCarousel";
import MobileImageCarousel from "./MobileImageCarousel";

import Navbar from "../Navbar/Navbar"; // 确保引入 Navbar
function HomePage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const homechooseRef = useRef(null);
  const homemidRef = useRef(null);
  const carouselRef = useRef(null);
  const contactRef = useRef(null);

  // Scroll to Homechoose
  const scrollToHomechoose = () => {
    homechooseRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to Homemid
  const scrollToHomemid = () => {
    homemidRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to ImageCarousel
  const scrollToCarousel = () => {
    carouselRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to Contact
  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {isMobile ? (
        <div>
          <Hometop />
          <Homechoose />
          <Homemid />

          <MobileImageCarousel />

          <Contact />
        </div>
      ) : (
        <div className="homepage-container">
          <Navbar
            scrollToHomechoose={scrollToHomechoose}
            scrollToHomemid={scrollToHomemid}
            scrollToCarousel={scrollToCarousel}
            scrollToContact={scrollToContact}
          />
          <Hometop />
          <div ref={homechooseRef}>
            <Homechoose />
          </div>
          <div ref={homemidRef}>
            <Homemid />
          </div>
          <div ref={carouselRef}>
            <ImageCarousel />
          </div>

          <div ref={contactRef}>
            <Contact />
          </div>
        </div>
      )}

      {/* <div className="homepage-container">
        <Hometop />
        <Homemid />
        <Homechoose />
        <Homevision />
        <Download />
        <Contact />
      </div> */}
    </>
  );
}

export default HomePage;
