import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Routes } from "react-router-dom";
import Homepage from "./components/HomePage/Homepage";
import Services from "./components/ServicesPage/Services";
import Contact from "./components/ServicesPage/Contact";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ImageCarousel from "./components/HomePage/ImageCarousel";
import Homemid from "./components/HomePage/Homemid";
import Homechoose from "./components/HomePage/Homechoose";
/* pages */

// import Navbar from "./components/MainPage/Navbar";

class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Navbar />

          <Routes>
            <Route path="/" exact element={<Homepage />} />

            <Route path="/about" element={<Homechoose />} />
            <Route path="/whatido" element={<Homemid />} />
            <Route path="/mywork" element={<ImageCarousel />} />
            <Route path="/contact-us" element={<Contact />} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </>
    );
  }
}
export default App;
